import vue from 'vue'

import { ValidationProvider, extend, configure } from 'vee-validate'
import { required, min_value, email, double } from 'vee-validate/dist/rules'

configure({
    classes: {
        valid: 'is-valid',
        invalid: 'is-invalid',
        dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
        // ...
    },
})

extend('min_value', {
    ...min_value,
    message: 'Bitte geben Sie einen Betrag ein',
})
extend('email', {
    ...email,
    message: 'E-Mail Adresse ungültig',
})
extend('required', {
    ...required,
    message: 'Dieses Feld wird benötigt',
})

configure({
    classes: {
        valid: 'is-valid',
        invalid: 'is-invalid',
        dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
        // ...
    },
})
