<template>
    <div>
        <div v-if="showForm">
            <ValidationObserver
                ref="observer"
                tag="form"
                v-slot="{ invalid, handleSubmit }"
            >
                <form
                    v-if="showForm"
                    @submit.prevent="handleSubmit(onSubmit)"
                    class="
                        items-start
                        grid grid-cols-1
                        md:grid-cols-2
                        gap-6
                        p-4
                        sm:p-8
                        bg-white
                        shadow-xl
                    "
                >
                    <ValidationProvider
                        rules="required"
                        v-slot="{ errors, classes }"
                    >
                        <label class="block">
                            <span class="text-gray-700" :class="classes"
                                >Vorname</span
                            >
                            <input
                                v-model="firstname"
                                type="text"
                                :class="classes"
                                class="block w-full mt-1 border form-input"
                                placeholder="Vorname"
                            />
                            <span class="form-error">{{ errors[0] }}</span>
                        </label>
                    </ValidationProvider>

                    <ValidationProvider
                        rules="required"
                        v-slot="{ errors, classes }"
                    >
                        <label class="block">
                            <span class="text-gray-700" :class="classes"
                                >Nachname</span
                            >
                            <input
                                v-model="lastname"
                                :class="classes"
                                type="text"
                                class="block w-full mt-1 border form-input"
                                placeholder="Nachname"
                            />
                            <span class="form-error">{{ errors[0] }}</span>
                        </label>
                    </ValidationProvider>

                    <ValidationProvider
                        rules="required|email"
                        v-slot="{ errors, classes }"
                    >
                        <label class="block">
                            <span class="text-gray-700" :class="classes"
                                >E-Mail</span
                            >
                            <input
                                v-model="email"
                                :class="classes"
                                type="email"
                                class="block w-full mt-1 border form-input"
                                placeholder="E-Mail"
                            />
                            <span class="form-error">{{ errors[0] }}</span>
                        </label>
                    </ValidationProvider>

                    <ValidationProvider
                        rules="required|min_value:3"
                        v-slot="{ errors, classes }"
                        ref="amountProvider"
                    >
                        <label class="block">
                            <span class="text-gray-700" :class="classes"
                                >Spendenbetrag</span
                            >
                            <money
                                v-model.lazy="amount"
                                v-bind="money"
                                type="text"
                                :class="classes"
                                class="block w-full mt-1 border form-input"
                                placeholder="0,00 €"
                            />
                            <span class="form-error">{{ errors[0] }}</span>
                        </label>
                    </ValidationProvider>

                    <button
                        type="submit"
                        class="submit-button colspan-2"
                        :disabled="invalid"
                        :class="[invalid ? 'inactive' : '']"
                    >
                        Zugang beantragen
                    </button>
                </form>

                <div v-if="message" class="error-message prose">
                    {{ message }}
                </div>
            </ValidationObserver>
        </div>
        <div v-else class="success-message prose">
            <h3>Vielen Dank!</h3>
            <p>
                Ihren Anmelde-Link erhalten Sie per E-Mail.<br />
                Wenn Sie die E-Mail nicht in Ihrem Posteingang finden, prüfen
                Sie bitte Ihren Spam-Ordner.
            </p>
        </div>
    </div>
</template>

<script>
import { Money } from 'v-money'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
    props: {
        postUrl: {
            type: String,
            required: true
        },
        projectId: {
            type: Number,
            required: true
        }
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        Money
    },
    mounted() {
        this.$nextTick(function () {
            this.resetValidation()
        })
    },
    methods: {
        resetValidation() {
            this.$refs.amountProvider.reset()
        },
        onSubmit() {
            console.log('submit')
            this.axios
                .post(this.postUrl, {
                    project: this.projectId,
                    source: 'website',
                    firstname: this.firstname,
                    lastname: this.lastname,
                    email: this.email,
                    amount: this.amount
                })
                .then((res) => {
                    this.showForm = false
                })
                .catch((error) => {
                    if (error.response) {
                        this.message = error.response.data.message
                    }
                })
        }
    },
    data: () => {
        return {
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' €',
                precision: 2,
                masked: false
            },
            showForm: true,
            message: '',
            firstname: '',
            lastname: '',
            email: '',
            amount: 0
        }
    }
}
</script>
