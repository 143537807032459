import Vue from 'vue'
Vue.config.silent = true

// MODULES
import './modules/vue2-smooth-scroll'
import './modules/vue-scrollactive'
import './modules/vue-axios'
import './modules/vee-validate'
// import 'modules/vue-plyr'
// import 'modules/vue-scroll-to-top'
// import 'modules/vue-scroll-to'

// OWN COMPONENTS
import PdfGenerationForm from './urkundenportal/PdfGenerationForm.vue'
Vue.component('up-pdf-generation-form', PdfGenerationForm)

import RegistrationForm from './urkundenportal/RegistrationForm.vue'
Vue.component('up-registration-form', RegistrationForm)

// VUE INSTANCE
const app = new Vue({
    el: '#app',
})

window.app = app
