import Alpine from 'alpinejs'
Alpine.data('stickynavigation', () => ({
    show: false,
    isOpen: false,
    init() {
        this.scrolled()
    },
    scrolled() {
        this.show =
            document.querySelector('#menu-trigger').getBoundingClientRect()
                .top < 0
    },
    toggle() {
        this.isOpen = !this.isOpen
    },
    close() {
        this.isOpen = false
    },
    open() {
        this.isOpen = true
    },
}))
