import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'

Alpine.plugin(collapse)
import './alpine/navigation'
import './alpine/donation-form'
import './alpine/contact-agreement'
import './alpine/stickynavigation'
import '../web-components/components.js'

window.Alpine = Alpine

Alpine.start()
