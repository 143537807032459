import Alpine from 'alpinejs'
Alpine.data('contactAgreement', (token, accepted, projectId) => ({
    isWorking: false,
    projectId: projectId,
    accepted: accepted,
    token: token,
    init() {
        this.$watch('accepted', (value, oldValue) => {
            this.sync(value)
        })
    },
    sync(value) {
        console.log(value, this.projectId)
        this.isWorking = true
        fetch('/api/urkunden-portal/contributor/agreement/' + this.token, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ value: value, projectId: this.projectId }),
        })
            .then((res) => {
                return res.json()
            })
            .then((data) => {
                this.isWorking = false
            })
    },
}))
