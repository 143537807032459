import Alpine from 'alpinejs'
Alpine.data('navigation', () => ({
    show: true,
    isOpen: false,
    toggle() {
        this.isOpen = !this.isOpen
    },
    close() {
        this.isOpen = false
    },
    open() {
        this.isOpen = true
    },
    scrolled() {},
}))
