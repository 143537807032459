<template>
    <div>
        <div class="mt-12 text-2xl text-center">
            <slot name="intro" v-bind="{ donatedAmountFormatted: donatedAmountFormatted }">
                <h3>
                    Sie haben
                    {{ donatedAmountFormatted }} gespendet. Dafür bedanken wir
                    uns herzlich!
                </h3>
            </slot>

            <div v-if="availableAmount > 0">
                <slot name="donationstatus" v-bind:availableAmountFormatted="availableAmountFormatted">
                    <div class="available-amount-text">
                        Sie können noch für
                        <div class="available-amount">
                            {{ availableAmountFormatted }}
                        </div>
                        Urkunden erstellen.
                    </div>
                </slot>
            </div>

            <div v-else>
                <slot name="errormessage" v-bind="{
                    generated: documentsGenerated,
                    donatedAmountFormatted: donatedAmountFormatted,
                }">
                    <div class="error-message">
                        Für den gespendeten Betrag wurden bereits
                        {{ documentsGenerated }} Urkunden im Gesamtwert von
                        {{ donatedAmountFormatted }} generiert. Das
                        Urkundenportal wurde daher deaktiviert.
                    </div>
                </slot>
            </div>
        </div>

        <ValidationObserver ref="observer" tag="form" v-slot="{ invalid, handleSubmit }">
            <form v-if="availableAmount > 0" @submit.prevent="handleSubmit(onSubmit)"
                class=" items-start grid grid-cols-1 md:grid-cols-2 gap-6 my-16 p-8 bg-white shadow-xl ">
                <ValidationProvider rules="required" v-slot="{ errors, classes }">
                    <label class="block">
                        <span class="text-gray-700" :class="classes">Name</span>
                        <input v-model="name" :class="classes" type="text"
                            class="block w-full mt-1 border form-input ring-0 focus:ring-0 focus:border-green-500 outline-transparent focus:outline-transparent"
                            placeholder="Name auf der Urkunde" />
                        <span class="form-error">{{ errors[0] }}</span>
                    </label>
                </ValidationProvider>

                <ValidationProvider rules="required" v-slot="{ errors, classes }">
                    <label class="block">
                        <span class="text-gray-700" :class="classes">Bäume</span>
                        <input v-model="treeCount" type="number" min="1" step="1" :class="classes"
                            class="block w-full mt-1 border form-input ring-0 focus:ring-0 focus:border-green-500 outline-transparent focus:outline-transparent" />
                        <span class="form-error">{{ errors[0] }}</span>
                    </label>
                </ValidationProvider>
                <button type="submit" class="submit-button flex items-center justify-center space-x-2 hover:bg-green-700"
                    :disabled="invalid || isWorking" :class="[invalid || isWorking ? 'inactive' : '']">
                    <span v-if="!isWorking">Urkunde downloaden</span>
                    <span v-else>Urkunde wird erstellt</span>
                    <svg v-if="isWorking" class="animate-spin -ml-1 mr-3 h-5 w-5 text-green-500"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class=" stroke-white" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                        </circle>
                        <path class="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                        </path>
                    </svg>
                </button>
                <div class="text-center flex flex-col h-full justify-center items-center">
                    <span>
                        Entspricht:
                        <span class="font-bold">{{
                            (treeCount * pricePerTree) | currencyFormat
                        }}</span>
                    </span>
                </div>
            </form>
        </ValidationObserver>
        <div v-if="message.length" class="p-4 mx-auto my-8 font-bold bg-red-100 rounded">
            {{ message }}
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { APISettings } from '../api.js'
import downloadBlob from '../includes/downloadBlob.js'

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data: () => {
        return {
            isWorking: false,
            message: '',
            name: '',
            treeCount: 0,
            availableAmount: 0,
            documentsGenerated: 0,
        }
    },
    props: {
        projectId: {
            type: Number,
            required: true,
        },
        pricePerTree: {
            type: Number,
            required: true,
        },
        postUrl: {
            type: String,
            required: true,
        },
        metaUrl: {
            type: String,
            required: true,
        },
        contributor: {
            type: Object,
            required: true,
        },
    },
    mounted: function () {
        ; (this.availableAmount = this.contributor.availableAmount),
            (this.documentsGenerated = this.contributor.documentsGenerated)
    },
    filters: {
        currencyFormat: function (value) {
            return new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
            }).format(value)
        },
    },
    computed: {
        donatedAmountFormatted() {
            return this.$options.filters.currencyFormat(this.contributor.amount)
        },
        availableAmountFormatted() {
            return this.$options.filters.currencyFormat(this.availableAmount)
        },
    },
    methods: {
        downloadBlob,
        async generatePdf(data) {
            const baseUrl = data.gotenberg_api_url
            const endpoint = '/forms/chromium/convert/url'

            const form = new FormData()
            form.append('paperHeight', '11.7')
            form.append('paperWidth', '8.27')
            form.append('marginTop', '0')
            form.append('marginRight', '0')
            form.append('marginBottom', '0')
            form.append('marginLeft', '0')
            form.append('landscape', 'false')
            form.append('printBackground', 'true')
            form.append('omitBackground', 'true')
            form.append('preferCssPageSize', 'true')
            form.append('url', data.url)

            const options = {
                method: 'POST',
                headers: {
                    'Gotenberg-Output-Filename': data.pdfFileName,
                    'x-api-key': data.gotenberg_api_key,
                    'Gotenberg-Trace': '',
                },
                body: form,
            }

            return fetch(baseUrl + endpoint, options)
        },
        async getPreviewLink() {
            return fetch(this.postUrl, {
                headers: APISettings.headers,
                method: 'POST',
                body: JSON.stringify({
                    token: this.contributor.token,
                    projectId: this.projectId,
                    name: this.name,
                    treeCount: parseInt(this.treeCount),
                }),
            })
        },
        async refreshMetaInfos() {
            return fetch(this.metaUrl, {
                token: this.contributor.token,
            })
                .then(async (response) => {
                    if (response.ok) {
                        const data = await response.json()
                        this.availableAmount = data.availableAmount
                        this.documentsGenerated = data.documentsGenerated
                    }
                })
        },
        resetForm() {
            this.name = ''
            this.treeCount = ''
            this.message = ''
            this.$refs.observer.reset()

        },
        /*
         * Erst werden die Formulardaten über /pdf/log_return_link als DatenObjekt gespeichert, dann wird ein Link zur PDF-Vorschau
         * generiert und per JSON zurückgegeben.
         * Dieser Preview-Link wird an Gotenberg geschickt. Der Inhalt des Preview-Links wird als PDF gerendert und zurückgegeben
         */
        async onSubmit() {
            this.isWorking = true
            this.getPreviewLink().then(async (response) => {
                if (response.ok) {
                    const data = await response.json()
                    const pdf = await this.generatePdf(data)
                    if (pdf.ok) {
                        const blob = await pdf.blob()
                        this.downloadBlob(blob, data.pdfFileName)
                        this.resetForm()
                    }
                } else {
                    const json = await response.json()
                    this.message = json.message
                }
                await this.refreshMetaInfos()
                this.isWorking = false
            })
        },
    },
}
</script>
